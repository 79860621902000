// PrimeChecker.js
import React, { useState } from 'react';
import NumberInput from './NumberInput';
import '../App.css';

const PrimeChecker = () => {
    // Zustände für die eingegebene Zahl, den ersten Teiler und Fehler
    const [number, setNumber] = useState('');
    const [firstDivisor, setFirstDivisor] = useState(null);
    const [error, setError] = useState(null);
    const [isPrimeMessage, setIsPrimeMessage] = useState(null);

    // Funktion zum Überprüfen, ob die eingegebene Zahl eine Primzahl ist
    const checkPrime = () => {
        const num = parseInt(number);
        // Überprüfung auf ungültige Eingabe (kleiner oder gleich 1)
        if (isNaN(num) || num <= 1) {
            setFirstDivisor(null);
            setError('Bitte gib eine Zahl größer als 1 ein.');
            setIsPrimeMessage(null);
            return;
        }

        // Überprüfung auf zu große Zahl
        if (num > 9999991) {
            setFirstDivisor(null);
            setError('Die Zahl darf nicht größer als 9999991 sein.');
            setIsPrimeMessage(null);
            return;
        }


        // Überprüfung auf Teiler bis zur Quadratwurzel der Zahl
        for (let i = 2; i <= Math.sqrt(num); i++) {
            if (num % i === 0) {
                setFirstDivisor(i);
                setError(null);
                setIsPrimeMessage(null);
                return;
            }
        }

        // Kein Teiler gefunden, die Zahl ist eine Primzahl
        setFirstDivisor(null);
        setError(null);
        setIsPrimeMessage('Die Zahl ist eine Primzahl!');
    };

    // Funktion zum Zurücksetzen der Eingabe und des Zustands
    const reset = () => {
        setNumber('');
        setFirstDivisor(null);
        setError(null);
        setIsPrimeMessage(null); // Setzt die Meldung zurück
    };

    // Funktion, die bei Formulareinsendung aufgerufen wird
    const handleSubmit = (e) => {
        e.preventDefault();
        checkPrime();
    };

    return (
        <div className="container">
            {/* Überschrift mit Stil und Untertitel */}
            <h1>Primzahl-<span className="badge">Checker</span></h1>
            {/* Formular zum Überprüfen von Primzahlen */}
            <form onSubmit={handleSubmit}>
                {/* Eingabefeld für die Zahl */}
                <NumberInput value={number} onChange={setNumber} />
                {/* Fehlermeldung, falls eine ungültige Eingabe vorliegt */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {/* Container für Überprüfen- und Reset-Buttons */}
                <div className="button-container">
                    {/* Button zum Überprüfen mit grünem Stil */}
                    <button type="submit" className="check-button btn-success">
                        Überprüfen
                    </button>
                    {/* Button zum Zurücksetzen mit rotem Stil */}
                    <button type="button" onClick={reset} className="reset-button btn-danger">
                        Reset
                    </button>
                </div>
            </form>
            {/* Anzeige, wenn es keine Fehler gibt und ein erster Teiler gefunden wurde */}
            {!error && firstDivisor !== null ? (
                <div>
                    <h3 >Die Zahl ist <span className='badge danger'>keine</span> Primzahl.</h3>
                    <h3>Der erste Teiler ist:<span className='badge danger'>{firstDivisor}.</span></h3>
                </div>
            ) : null}
            {/* Anzeige, wenn es keine Fehler gibt und kein Teiler gefunden wurde (Primzahl) */}
            {!error && !firstDivisor && isPrimeMessage ? <h3 className='alert alert-success'>{isPrimeMessage}</h3> : null}
        </div>
    );
};

export default PrimeChecker;
