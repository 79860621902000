import React from 'react';
function Footer() {
    return (
        <footer>
            <div className="container footer">
                <p>© 2024 Hanno Liehmann</p>
            </div>
        </footer>
    );
}
export default Footer;
