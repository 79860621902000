// NumberInput.js
import React from 'react';

const NumberInput = ({ value, onChange }) => {
    return (
        <input
            type="number"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export default NumberInput;
