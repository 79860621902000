// App.js
import React from 'react';
import PrimeChecker from './component/PrimeChecker';
import Footer from './component/footer';

function App() {
  return (
    <div className="App">
      <PrimeChecker />
      <Footer />
    </div>
  );
}

export default App;
